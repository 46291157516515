import { ALL_BRANDS, UNKNOWN } from './consts';
import { MappedSegment, Orders, Passenger, Segment } from './types';

export function addLeadingZero(number: number): string {
    return number > 0 && number < 10 ? `0${number}` : `${number}`;
}

export const normalizeDate = (rawDate: string | number | Date): string => {
    const date = new Date(rawDate);

    return `${addLeadingZero(date.getDate())}.${addLeadingZero(date.getMonth() + 1)}.${date.getFullYear()}`;
};

function mapSegment(segments: Segment[]): MappedSegment[] {
    return segments.map((segment) => ({
        departureAirportCode: segment.departureAirportCode,
        arrivalAirportCode: segment.arrivalAirportCode,
        departureDate: normalizeDate(segment.departureLocalIso),
        flightNumber: segment.flightNumber,
        ak: segment.ak,
        class: segment.class,
        isValidForUpgrade: segment.segmentActions.upgrade.isAvailable,
        statusVisual: segment.statusVisual,
        segmentId: segment.segmentId,
    }));
}

export function mapOrder(order: Orders, flightNumber: string) {
    const { segments = [], passengers, offers = [] } = order.objects[0];
    const mappedSegments = mapSegment(segments);
    const targetSegment = mappedSegments.find(
        (segment: MappedSegment) =>
            segment.flightNumber === flightNumber &&
            (segment.statusVisual === 'active' || segment.statusVisual === 'registered')
    );

    if (targetSegment) {
        const brand =
            offers.find(
                ({ segmentId, brandName }) => segmentId === targetSegment.segmentId && ALL_BRANDS.includes(brandName)
            )?.brandName || UNKNOWN;

        return {
            segment: targetSegment,
            brand,
            numberOfPassengersWithSeats: passengers.filter((passenger: Passenger) => passenger.type !== 'INFANT')
                .length,
        };
    }

    throw new Error();
}
