/* eslint-disable camelcase */
import React from 'react';
import cn from 'classnames';
import { ReactComponent as Plane } from 'icons/interface/plane.svg';

import styles from './Segment.module.scss';

export function Segment({ direction, departure, arrival, children }) {
    return (
        <div className={styles.root} data-testid="checkIn-ServiceItemSegment">
            <div className={styles.header}>
                <Plane className={cn(styles.plane, direction.toLowerCase() === 'back' && styles.rotate)} />
                <div className={styles.headerSubText}>
                    {departure} — {arrival}
                </div>
            </div>
            {children}
        </div>
    );
}
