/* eslint-disable */
import React from 'react';
import { Route, IndexRoute } from 'react-router';
import DynamicImport from 'components/common/dynamic.import';

const Bonuses = (props) => <DynamicImport load={() => import('./index')} {...props} />;

const Transactions = (props) => <DynamicImport load={() => import('./Transactions')} {...props} />;

const AcceptInvite = (props) => <DynamicImport load={() => import('./accept-invite')} {...props} />;

export const bonusesRoutes = () => [
    <Route path="bonuses/acceptInvite" component={AcceptInvite} key="acceptInvite" />,
    <Route path="bonuses" key="main">
        <IndexRoute component={Bonuses} />
        <Route path="transactions" component={Transactions} />
    </Route>,
];
