import { fetcher } from 'schedule/shared/api';

import { mapAvailableDates } from '../lib';
import { AvailableFlightDatesResponseDto } from './types';

type RouteParams = {
    departureCityCode: string;
    arrivalCityCode: string;
};

export async function getAvailableFlightDatesApi({
    departureCityCode,
    arrivalCityCode,
}: RouteParams): Promise<DateIso[] | null> {
    if (!departureCityCode || !arrivalCityCode) return null;

    const searchParams = new URLSearchParams({
        departure: departureCityCode,
        arrival: arrivalCityCode,
    });

    const qs = searchParams.toString();

    const response = await fetcher<AvailableFlightDatesResponseDto>(`/api/v1/flights/available-flight-dates?${qs}`, {
        method: 'GET',
    });

    return mapAvailableDates(response);
}
