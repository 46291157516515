/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { MappedSegment } from 'entities/upgrade/order';

import type { Seats } from './types';

const initialState: Seats = {
    seatsCount: 0,
    upgradeSegment: null,
    numberOfPassengersWithSeats: 0,
    isLoadingSeatsCount: false,
    isErrorSeatsCount: false,
};

const seatsSlice = createSlice({
    name: 'seats',
    initialState,
    reducers: {
        setSeatsCount(state, { payload }: PayloadAction<number>) {
            state.seatsCount = payload;
        },
        setIsLoadingSeatsCount(state, { payload }: PayloadAction<boolean>) {
            state.isLoadingSeatsCount = payload;
        },
        setIsErrorSeatsCount(state, { payload }: PayloadAction<boolean>) {
            state.isErrorSeatsCount = payload;
        },
        setNumberOfPassengersWithSeats(state, { payload }: PayloadAction<number>) {
            state.numberOfPassengersWithSeats = payload;
        },
        setUpgradeSegment(state, { payload }: PayloadAction<MappedSegment | null>) {
            state.upgradeSegment = payload;
        },
    },
});

export const selectors = {
    isLoadingSeatsCount(state: RootState) {
        return state.upgrade.seats.isLoadingSeatsCount;
    },
    seatsCount(state: RootState) {
        return state.upgrade.seats.seatsCount;
    },
    numberOfPassengersWithSeats(state: RootState) {
        return state.upgrade.seats.numberOfPassengersWithSeats;
    },
    isErrorSeatsCount(state: RootState) {
        return state.upgrade.seats.isErrorSeatsCount;
    },
    isSeatsOut(state: RootState) {
        return (
            selectors.numberOfPassengersWithSeats(state) > selectors.seatsCount(state) ||
            selectors.isErrorSeatsCount(state) ||
            selectors.isLoadingSeatsCount(state)
        );
    },
    upgradeSegment(state: RootState) {
        return state.upgrade.seats.upgradeSegment;
    },
};

export const { name, reducer, actions } = seatsSlice;
