import { Brand } from './types';

export const upgrade = {
    /*
     * Период времени до начала регистрации до которого доступен апгрейд, в секундах (1 час)
     */
    availableTime: 60 * 60 * 1000,
    /*
     * Период времени до начала регистрации до которого доступен апгрейд при определенных условиях, в
     * секундах (30 минут)
     */
    extraAvailableTime: 30 * 60 * 1000,
    /*
     * Период времени до которого доступен переход к регистрации, в секундах (40 минут)
     */
    registrationAvailableTime: 40 * 60 * 1000,
    refreshTime: 1000,
    serviceGuid: 'dd7baa0b-5516-4525-8866-fc09f4988c8e',
};

export const errors = {
    processError: 'processError',
    noFreeSeatsInBusinessClass: 'noFreeSeatsInBusinessClass',
    businessClassUpgradeError: 'businessClassUpgradeError',
};

export const errorTexts = {
    processError: (isAvailableOnlySleepingRow: boolean) =>
        isAvailableOnlySleepingRow
            ? 'К сожалению, сейчас нельзя забронировать спальный ряд.\nПожалуйста, обратитесь на стойку регистрации минимум за час до вылета или раньше.\nЕсли места появятся — вы сможете пересесть.'
            : 'К сожалению, сейчас нельзя забронировать место в Евробизнесе.\nПожалуйста, обратитесь на стойку регистрации минимум за час до вылета или раньше.\nЕсли места появятся — вы сможете пересесть.',
    noFreeSeatsInBusinessClass: (isAvailableOnlySleepingRow: boolean) =>
        isAvailableOnlySleepingRow
            ? 'Не осталось свободных мест в спальном ряду, попробуйте позднее'
            : 'Не осталось свободных мест в бизнес классе, попробуйте позднее',
    businessClassUpgradeError: 'Возникла ошибка при запросе услуги повышения класса обслуживания. Попробуйте позднее',
    paymentError: `Ошибка оплаты.\nПожалуйста, проверьте данные банковской карты и повторите попытку. Также попробуйте другую карту.\nЕсли это не поможет, обратитесь на стойку регистрации в аэропорту за 1 час до вылета или раньше. Вы сможете пересесть при наличии свободных мест.`,
};

export const info = {
    hasBusinessClass: 'Вы уже имеете билет в Евробизнесе на этом рейсе',
};

export const mobileAppFlag = 'FROM_APP';

export const UNKNOWN = 'UNKNOWN';

export const BRANDS: Record<Brand, Brand> = {
    MINIMUM_NEW: 'MINIMUM_NEW',
    MINIMUM_PLUS: 'MINIMUM_PLUS',
    OPTIMUM_NEW: 'OPTIMUM_NEW',
    OPTIMUM_PLUS: 'OPTIMUM_PLUS',
    PREMIUM_NEW: 'PREMIUM_NEW',
    COMFORT: 'COMFORT',
    UNKNOWN,
};

export const ALL_BRANDS = Object.values(BRANDS);
