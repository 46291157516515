import React from 'react';
import cn from 'classnames';

import styles from './Backdrop.module.scss';

export function Backdrop({ isOpen = true, disableOverlayClick, onClick = () => {} }) {
    return (
        <div
            className={cn(styles.root, isOpen && styles.isOpen)}
            onClick={disableOverlayClick ? null : onClick}
            onKeyPress={disableOverlayClick ? null : onClick}
        />
    );
}
