/* eslint-disable */
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import InputElement from 'react-input-mask';
import {DayPickerSingleDateController} from 'react-dates';

import {ReactComponent as ArrowForward} from 'icons/interface/arrow-forward.svg';
import {ReactComponent as ArrowBack} from 'icons/interface/arrow-back.svg';
import {ReactComponent as Calendar} from './calendar.svg';

const STYLES = {
    CALENDAR_ICON: {
        width: '1.5rem',
        height: '1.5rem'
    },
    NAV_BUTTON: {
        width: '3rem',
        height: '3rem'
    },
    INPUT: {
        width: '100%'
    }
};

const CalendarIcon = () => (
    <span className="dateInput-icon">
        <Calendar style={STYLES.CALENDAR_ICON} />
    </span>
);

class DateInput extends React.Component {
    static defaultProps = {
        format: 'DD.MM.YYYY',
        Container: 'div',
        id: '',
        name: '',
        caption: '',
        disabled: false,
        mask: null,
        maskChar: null,
        showIcon: false,
        className: 'inputCover',
        error: '',
        inline: false,
        onBlur: () => {},
        onChange: () => {},
        numberOfMonths: 1
    };

    constructor(props) {
        super(props);

        const {value, format} = this.props;

        this.state = {
            inputValue: value ? value.format(format) : '',
            value: value || null,
            showCalendar: false
        };
    }

    selectHandler = (value) => {
        const {format} = this.props;

        this.setState({
            value,
            inputValue: value.format(format),
            showCalendar: false
        });

        this.props.onChange(value);
    };

    onChange = (value) => {
        this.setState({
            value
        });
    };

    inputChangeHandler = (event) => {
        const {format} = this.props;
        const {value} = event.target;
        const date = moment(value, format);

        this.setState({
            inputValue: value
        });

        if (value.length === 0) {
            this.setState({
                value: null
            });
            this.props.onChange(null);
        }

        if (value.length === 10 && date.isValid()) {
            this.setState({
                value: date
            });
            this.props.onChange(date);
        }
    };

    inputBlurHandler = (event) => {
        this.props.onBlur(event);
    };

    render() {
        const {value, inputValue, showCalendar} = this.state;

        const {
            id,
            label,
            name,
            className,
            placeholder,
            disabled,
            Container,
            inline,
            error,
            showIcon,
            mask,
            maskChar,
            numberOfMonths,
            caption,
            hiddenLabel
        } = this.props;

        return (
            <Container className={classNames('dateInput', className, {'is-error': error, inline})}>
                <label htmlFor={id} className={classNames({'is-hidden': hiddenLabel && !value && !error})}>
                    {label} {error && <span className="text-regular">{error}</span>}
                </label>
                <InputElement
                    ref={(elem) => {
                        this.inputRef = elem;
                    }}
                    placeholder={placeholder}
                    style={STYLES.INPUT}
                    disabled={disabled}
                    tabIndex="-1"
                    className="input ant-calendar-picker-input ant-input"
                    value={inputValue}
                    onChange={this.inputChangeHandler}
                    name={name}
                    mask={mask}
                    maskChar={maskChar}
                    onBlur={this.inputBlurHandler}
                    onClick={() => this.setState({showCalendar: !showCalendar})}
                />
                {showCalendar && !disabled && (
                    <div className="DayPicker-dropdown">
                        <DayPickerSingleDateController
                            date={value}
                            onDateChange={this.selectHandler}
                            focused={this.state.focused}
                            onOutsideClick={() => this.setState({showCalendar: false})}
                            onFocusChange={({focused}) => this.setState({focused})}
                            navPrev={<ArrowBack style={STYLES.NAV_BUTTON} aria-hidden="true" />}
                            navNext={<ArrowForward style={STYLES.NAV_BUTTON} aria-hidden="true" />}
                            numberOfMonths={numberOfMonths}
                            hideKeyboardShortcutsPanel
                        />
                    </div>
                )}
                {showIcon && <CalendarIcon />}
                {caption && <span className="inputCover-caption">{caption}</span>}
            </Container>
        );
    }
}

export default DateInput;
