import { fetcher } from 'checkin/shared/api';
import { toCamelCase } from 'checkin/shared/lib/toCamelCase';
import { ERRORS } from 'checkin/shared/consts';

export async function getServicesApi(cartId) {
    // http://digital-ms-mcs-checkin-production.srv.utair.io/docs#/Services/cart_get_services_checkin_api_v1_cart__cart_uuid__service_list__get
    const { data } = await fetcher(`checkin/api/v1/cart/${cartId}/services`);

    return toCamelCase(data);
}

export const SERVICES_ERROR_CODES = {
    UNABLE_TO_GET_SEGMENT_BY_ID: 20,
    CART_WAS_ABANDONED: 94,
    CART_UPGRADE_IS_BEING_PROCESSED: 95,
    CART_IS_BEING_PROCESSED: 96,
    CART_IS_NOT_AVAILABLE: 97,
    CART_IS_FINALIZED: 98,
};

export const SERVICES_WARNING_CODES = {
    CANNOT_RELEASE_LOCK: 4000,
};

export function getServicesError(errorCode) {
    switch (errorCode) {
        case SERVICES_ERROR_CODES.CART_WAS_ABANDONED:
            return 'Ваша сессия истекла, пройдите регистрацию заново';

        case SERVICES_ERROR_CODES.CART_UPGRADE_IS_BEING_PROCESSED:
            return 'Оформляется услуга апгрейда, дождитесь окончания';

        case SERVICES_ERROR_CODES.CART_IS_BEING_PROCESSED:
            return 'Ваша корзина оформляется, дождитесь окончания процесса';

        case SERVICES_ERROR_CODES.CART_IS_NOT_AVAILABLE:
            return 'Корзина не доступна, пройдите регистрацию заново';

        case SERVICES_ERROR_CODES.CART_IS_FINALIZED:
            return 'Корзина оформлена, пройдите регистрацию заново';

        case SERVICES_WARNING_CODES.CANNOT_RELEASE_LOCK:
            return 'Ошибка при обновлении услуг в корзине, попробуйте еще раз';

        case 5204:
            return 'Ошибка применения скидки, попробуйте еще раз или обновите страницу';

        case 8000:
        case 8003:
        case 8004:
            return 'Ошибка доступности услуг, попробуйте еще раз или обновите страницу';

        case 8001:
        case 8006:
            return 'Ошибка добавления услуг, попробуйте еще раз или обновите страницу';

        case 8002:
            return 'Ошибка добавления услуги, нельзя добавить более 1 услуги на пассажира';

        case 8005:
            return 'Ошибка удаления услуги из корзины, попробуйте еще раз или обновите страницу';

        default:
            return ERRORS.UNKNOWN_ERROR;
    }
}

export async function applyServicesApi(cartId = '', services = []) {
    // http://digital-ms-mcs-checkin-production.srv.utair.io/docs#/Services/cart_apply_services_checkin_api_v1_cart__cart_uuid__service_apply__post
    const { data } = await fetcher(`checkin/api/v1/cart/${cartId}/services`, {
        method: 'PATCH',
        body: JSON.stringify(
            services.map(({ passengerIdAsm, segmentIdAsm, guid, quantity = 0 }) => ({
                passenger_id_asm: passengerIdAsm,
                segment_id_asm: segmentIdAsm,
                guid,
                quantity,
            }))
        ),
    });

    return toCamelCase(data);
}
