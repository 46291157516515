import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { REDIRECT_LINKS } from 'consts';

export function useBonusesLink() {
    const media = useSelector((state) => state.media);

    const [link, setLink] = useState(REDIRECT_LINKS.BONUSES);

    useEffect(() => {
        setLink(media.phone ? REDIRECT_LINKS.USER_MOBILE : REDIRECT_LINKS.BONUSES);
    }, [media]);

    return link;
}

export function useBonusesTransactionsLink() {
    const media = useSelector((state) => state.media);

    const [link, setLink] = useState(REDIRECT_LINKS.BONUSES_TRANSACTIONS);

    useEffect(() => {
        setLink(media.phone ? REDIRECT_LINKS.USER_MOBILE : REDIRECT_LINKS.BONUSES_TRANSACTIONS);
    }, [media]);

    return link;
}

export function useProfileLink() {
    const media = useSelector((state) => state.media);

    const [link, setLink] = useState(REDIRECT_LINKS.USER);

    useEffect(() => {
        setLink(media.phone ? REDIRECT_LINKS.USER_MOBILE : REDIRECT_LINKS.USER);
    }, [media]);

    return link;
}
