import { formatPrice } from 'utils/formatter';
import { CURRENCIES } from 'consts';

export function mapDataToModel(data) {
    if (!(data && typeof data === 'object')) return {};

    return {
        ...data,
        hasAvailableService: Object.values(data.serviceAvailability).some((isAvailable) => isAvailable),
    };
}

export function isNeededService(service, segmentId, passengerId) {
    return service.segmentIdAsm === segmentId && service.passengerIdAsm === passengerId;
}

export function groupBy(items, prop) {
    return items.reduce((acc, item) => {
        if (acc[item[prop]]) {
            acc[item[prop]].push(item);
        } else {
            acc[item[prop]] = [item];
        }

        return acc;
    }, {});
}

export function findService(services = [], segmentId = '', passengerId = '') {
    return services.find((service) => isNeededService(service, segmentId, passengerId));
}

export function getIsSelected(services, segmentId, passengerId) {
    return services.some((service) => isNeededService(service, segmentId, passengerId) && service.quantity);
}

export function changeSelectedServices({ selected, guid, segmentIdAsm, passengerIdAsm, quantity }) {
    return [
        ...selected.filter((sel) => !isNeededService(sel, segmentIdAsm, passengerIdAsm)),
        {
            passengerIdAsm,
            segmentIdAsm,
            guid,
            quantity,
        },
    ];
}

export function getTotalPrice(services, applicability) {
    return services.reduce((acc, { segmentIdAsm, passengerIdAsm, quantity }) => {
        const { price } = findService(applicability, segmentIdAsm, passengerIdAsm) || {};

        return acc + price * quantity;
    }, 0);
}

export function mapServicesForRemove(services) {
    return services.map((service) => ({ ...service, quantity: 0 }));
}

export function mapService(services, segments, originCheckinServices, originBookedServices, labels) {
    if (!(services.length && segments.length)) return { items: [], hasAddedService: false };

    const servicesByCategory = groupBy(services, 'category');
    const checkinServicesByGuid = groupBy(originCheckinServices, 'guid');
    const bookedServicesByGuid = groupBy(originBookedServices, 'guid');

    let hasAddedService = false;

    const items = Object.entries(servicesByCategory).map(([category, children]) => ({
        label: labels[category],
        value: category,
        children: children.map(({ guid, applicability, ...props }) => {
            const bookedServices = bookedServicesByGuid[guid] || [];
            const checkinServices = checkinServicesByGuid[guid] || [];

            if (checkinServices.length && !hasAddedService) {
                hasAddedService = true;
            }

            const segmentsWithServices = segments.reduce((acc, segment) => {
                const isAvailableSegment = applicability.some((item) => segment.segmentIdAsm === item.segmentIdAsm);

                if (isAvailableSegment) {
                    const segmentWithServices = {
                        ...segment,
                        passengers: segment.passengers.reduce((accumulator, passenger) => {
                            const availableService = findService(
                                applicability,
                                segment.segmentIdAsm,
                                passenger.passengerIdAsm
                            );

                            if (availableService) {
                                const bookedService = findService(
                                    bookedServices,
                                    segment.segmentIdAsm,
                                    passenger.passengerIdAsm
                                );

                                accumulator.push({
                                    ...passenger,
                                    price: formatPrice(availableService.price, 'RUR', true, true),
                                    formattedOldPrice: formatPrice(
                                        availableService.oldPrice,
                                        CURRENCIES.RUR.value,
                                        false,
                                        false
                                    ),
                                    bookedQuantity: bookedService?.quantity || 0,
                                });
                            }

                            return accumulator;
                        }, []),
                    };

                    acc.push(segmentWithServices);
                }

                return acc;
            }, []);

            return {
                ...props,
                segments: segmentsWithServices,
                id: guid,
                checkinServices,
                applicability,
                hasBookedServices: !!bookedServices.length,
                minPrice: formatPrice(Math.min(...applicability.map(({ price }) => price)), 'RUR', true, true),
            };
        }),
    }));

    return { items, hasAddedService };
}

export function getDiffServices(selectedServices = [], prevServices = []) {
    return selectedServices.filter((sel) => {
        const prevService = prevServices.find((prev) => isNeededService(sel, prev.segmentIdAsm, prev.passengerIdAsm));

        if ((!prevService && !sel.quantity) || (prevService && sel.quantity === prevService.quantity)) return false;

        return true;
    });
}

export function getHasChangeInBasket(basket, services) {
    if (!basket.length && services.every((service) => !service.quantity)) return false;

    return JSON.stringify(basket) !== JSON.stringify(services);
}
