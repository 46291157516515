import React, { useState } from 'react';
import cn from 'classnames';
import { t } from 'i18next';
import { Popover, POPOVER_DIRECTION } from 'components/common/popover';

import styles from './TicketSelector.module.scss';
import { ALL_TICKETS } from './conts';

export function TicketSelector({ tickets, activeTicketType, setActiveTicketType, onRef, isError }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleMouseDown = () => setIsDropdownOpen(!isDropdownOpen);
    const handleFocus = () => setIsDropdownOpen(true);
    const handleBlur = () => setIsDropdownOpen(false);

    const handleClick = (type) => {
        setActiveTicketType(type);
        setIsDropdownOpen(false);
    };

    return (
        <div className={cn('SearchWidget-box -tickets', styles.root)}>
            <Popover
                defaultDirection={POPOVER_DIRECTION.BOTTOM}
                tooltip={<div>{t('validator.errors.ticket_type_required')}</div>}
                isShown={isError && !isDropdownOpen && !activeTicketType}
                disableHover
            >
                <input
                    className={cn('SearchWidget-input -select', {
                        'is-error': isError && !isDropdownOpen && !activeTicketType,
                    })}
                    placeholder={t('flights_search.ticket_type')}
                    type="text"
                    ref={onRef}
                    value={ALL_TICKETS[activeTicketType]}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onMouseDown={handleMouseDown}
                    readOnly
                />
            </Popover>

            <div className={cn('SearchWidget-dropdown', styles.dropdown)} aria-hidden={!isDropdownOpen}>
                <ul className={styles.list}>
                    {tickets.map((ticketType) => (
                        <li
                            key={ticketType}
                            className={cn(styles.listItem, ticketType === activeTicketType && styles.active)}
                            role="menuitem"
                            onClick={() => handleClick(ticketType)}
                            onKeyPress={() => handleClick(ticketType)}
                        >
                            {ALL_TICKETS[ticketType]}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
