import { combineReducers } from '@reduxjs/toolkit';
import { reducer as media } from 'redux-mediaquery';
import socialAuth from 'components/Account/social/reducers';
import { qratorError } from 'components/common/QratorErrorModal';

import auth from './auth';
import orders from './orders';
import bonuses from './bonuses';
import user from './user';
import companions from './companions.user';
import sign from './sign';
import family from './family';
import sysmessages from './sysmessages';
import orderManage from './order-manage';
import schedule from './schedule';
import socials from './social.networks';
import checkIn from './checkin';
import subscriptions from './subscriptions';
import countries from './countries';
import changePersonalData from './change-personal-data';
import onlinePanel from './online-panel';
import chatWidget from './chat-widget';
import { upgradeReducers } from './upgrade';

export default combineReducers({
    auth,
    orders,
    bonuses,
    user,
    companions,
    sign,
    family,
    sysmessages,
    orderManage,
    schedule,
    socialAuth,
    media,
    socials,
    checkIn,
    subscriptions,
    countries,
    changePersonalData,
    qratorError,
    onlinePanel,
    chatWidget,
    upgrade: upgradeReducers,
});
