import { SERVICE_TYPE } from './consts';
import { findService } from './lib';

const TICKET_DIRECTION = {
    ONE_WAY: 'ow',
    ROUND_TRIP: 'rt',
};

const DIRECTION = {
    TO: 'to',
    BACK: 'back',
};

export function getServiceLoadedParams(cartId, services, segments) {
    const guids = services.map(({ guid }) => guid);
    const ticketDirection = segments.some(({ direction }) => direction === DIRECTION.BACK.toUpperCase())
        ? TICKET_DIRECTION.ROUND_TRIP
        : TICKET_DIRECTION.ONE_WAY;

    return {
        cart_uuid: cartId,
        direction: ticketDirection,
        guid: guids,
    };
}

function getSegmentsCityCodes(segments) {
    return segments.map((segment) => `${segment.departureAirportCode}_${segment.arrivalAirportCode}`);
}

function getCostOnSegments(segments) {
    return segments.map((segment) => segment.passengers.map(({ price }) => price).toString());
}

function getDirection(segments) {
    const counts = { TO: 0, BACK: 0 };

    segments.forEach(({ direction }) => {
        counts[direction] += 1;
    });

    if (counts.TO && counts.BACK) {
        return TICKET_DIRECTION.ROUND_TRIP;
    }

    if (counts.TO) {
        return DIRECTION.TO;
    }

    if (counts.BACK) {
        return DIRECTION.BACK;
    }

    return null;
}

export function getServiceInfoParams({ cartId, segments, guid, serviceType }) {
    const cost = getCostOnSegments(segments);

    const params = {
        cart_uuid: cartId,
        guid,
        type: serviceType,
        cost,
    };

    if (serviceType === SERVICE_TYPE.MEAL) {
        const segmentsCityCodes = getSegmentsCityCodes(segments);
        params.segments = segmentsCityCodes;
    }

    if (serviceType === SERVICE_TYPE.BAGGAGE) {
        const direction = getDirection(segments);
        params.direction = direction;
    }

    return params;
}

function getOnlyAddedServices(services) {
    return services.filter(({ quantity }) => quantity > 0);
}

export function getServiceSaveClickedParams({ cartId, segments, applicability, guid, diffServices, serviceType }) {
    const onlyAddedServices = getOnlyAddedServices(diffServices);
    const passengersIds = onlyAddedServices.map(({ passengerIdAsm }) => passengerIdAsm);
    const uniqPassengersIds = [...new Set(passengersIds)];
    const direction = getDirection(segments);

    const savedServices = segments.reduce((acc, segment) => {
        const costOnSegment = segment.passengers.reduce((accPassenger, passenger) => {
            const { quantity, guid: passengerServiceGuid } =
                findService(diffServices, segment.segmentIdAsm, passenger.passengerIdAsm) || {};
            const { price } = findService(applicability, segment.segmentIdAsm, passenger.passengerIdAsm) || {};

            if (quantity || passengerServiceGuid) {
                accPassenger.push({
                    segment_id_asm: segment.segmentIdAsm,
                    passenger_id_asm: passenger.passengerIdAsm,
                    guid: passengerServiceGuid,
                    count: quantity,
                    cost: (quantity * price) / 100,
                });
            }

            return accPassenger;
        }, []);

        if (costOnSegment.length) {
            acc.push(costOnSegment);
        }

        return acc;
    }, []);

    return {
        cart_uuid: cartId,
        guid,
        type: serviceType,
        passenger_id_asm: uniqPassengersIds,
        saved_services: savedServices,
        ...(serviceType === SERVICE_TYPE.BAGGAGE && { direction }),
        ...(serviceType === SERVICE_TYPE.MEAL && { segment: getSegmentsCityCodes(segments) }),
    };
}

export function getServiceCrossClickedParams({ cartId, guid, serviceType }) {
    return {
        cart_uuid: cartId,
        guid,
        type: serviceType,
    };
}

export function getBackClickedParams(cartId) {
    return {
        cart_uuid: cartId,
    };
}

export function getContinueClickedParams({ cartId, services, checkinServices, countName }) {
    const onlyNeededCheckinServices = checkinServices.filter((checkinService) =>
        services.some((service) => checkinService.guid === service.guid)
    );

    const count = onlyNeededCheckinServices.map(({ quantity }) => quantity);
    const cost = onlyNeededCheckinServices.reduce((acc, checkinService) => {
        const { applicability } = services.find((service) => service.guid === checkinService.guid);
        const { price } = findService(applicability, checkinService.segmentIdAsm, checkinService.passengerIdAsm);

        return acc + (price * checkinService.quantity) / 100;
    }, 0);

    return {
        cart_uuid: cartId,
        [countName]: count,
        cost,
    };
}
