import cn from 'classnames';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useDebounce } from 'schedule/shared/lib';
import { type City } from 'schedule/shared/model';
// import listenForOutsideClick from 'utils/click-outside';
// import { ReactComponent as Close } from 'icons/interface/close.svg';

import { useGetCities } from '../../model';
import styles from './AutocompleteCity.module.scss';

type AutocompleteCityProps = {
    isModal?: boolean;
    initialCity: City | null;
    placeholder: string;
    className?: string;
    onChange: (val: City | null) => void;
};

export function AutocompleteCity({
    className = '',
    isModal = false,
    initialCity,
    placeholder,
    onChange,
}: AutocompleteCityProps) {
    const optionsRef = useRef(null);
    const [isFocus, setIsFocus] = useState<boolean>(false);

    const [selected, setSelected] = useState<City | null>();
    const [inputValue, setInputValue] = useState<string | null>(null);
    const inputCityDebounced = useDebounce<string | null>(inputValue, 300);

    const { cities, getCities } = useGetCities();

    // const [listening, setListening] = useState(false);
    // const [preselect, setPreselect] = useState(false);
    // const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setSelected(initialCity);
        setInputValue(initialCity?.name || null);

        return () => {
            setSelected(null);
            setInputValue(null);
        };
    }, [initialCity]);

    useEffect(() => {
        if (initialCity?.name !== inputValue) {
            getCities({ query: inputCityDebounced || '' });
        }
    }, [inputCityDebounced]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isFocus && !cities.length) {
            getCities({ popular: true });
        }
    }, [cities.length, getCities, isFocus]);

    const onSelectOption = (city: City) => {
        setSelected(city);
        setIsFocus(false);
        onChange(city);
    };

    const toggleFocus = () => {
        setInputValue(selected?.name || null);
        setIsFocus(!isFocus);
    };

    const handleCityInputChange = (eOpts: ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value },
        } = eOpts;

        setInputValue(value);
    };

    const renderOption = (city: City) => (
        <div
            key={city.code}
            className={cn(styles.listitem, { [styles.active]: selected?.code === city.code })}
            onClick={() => onSelectOption(city)}
            aria-hidden
        >
            <div>
                <div className={styles.cityName}>{city.name}</div>
                <div className={styles.countryName}>{city?.countryName}</div>
            </div>
            <div className={styles.cityCode}>{city.code}</div>
        </div>
    );

    return (
        <div className={cn(className, styles.autocompleteContainer, { [styles.focused]: isFocus })}>
            <div className={styles.city} aria-hidden={isFocus}>
                <span className={styles.name}>{selected?.name}</span>
                <span className={styles.code}>{selected?.code}</span>
            </div>
            <div className={cn(styles.dropdown)} ref={optionsRef} aria-hidden={!isFocus}>
                {cities && !!cities.length && cities.map(renderOption)}
            </div>
            {isModal ? (
                selected && !selected.name && <div className={styles.placeholder}>{placeholder}</div>
            ) : (
                <input
                    id={initialCity?.code}
                    className={styles.autocompleteInput}
                    value={inputValue || ''}
                    placeholder={placeholder}
                    onClick={(eOpts) => eOpts.currentTarget.select()}
                    onFocus={toggleFocus}
                    onBlur={toggleFocus}
                    // aria-hidden={!isFocus}
                    onChange={handleCityInputChange}
                />
            )}
        </div>
    );
}
