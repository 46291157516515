/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Message } from 'shared/ui/Notifications';

interface Messages {
    messages: Message[];
}

const initialState: Messages = {
    messages: [],
};

const cartSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addMessage(state, { payload }: PayloadAction<Omit<Message, 'id'>>) {
            const messagesLength = state.messages.length;
            const hasMessages = !!messagesLength;

            state.messages.push({
                id: hasMessages ? state.messages[messagesLength - 1].id + 1 : 0,
                type: payload.type,
                text: payload.text,
            });
        },
        removeMessage(state, { payload }: PayloadAction<Message>) {
            const newMessages = state.messages.filter((message) => message.id !== payload.id);

            state.messages = newMessages;
        },
    },
});

export const selectors = {
    selectMessages: (state: RootState) => state.upgrade.notifications.messages,
};

export const { actions, name, reducer } = cartSlice;
