import { MomentInput } from 'moment';

import { getDateObject } from './getDateObject';

export function getEndOfWeek(datetime?: MomentInput) {
    const dateObject = getDateObject(datetime);
    const endOfWeek = dateObject.clone().endOf('week');

    return endOfWeek;
}
