import { toCamelCase } from 'schedule/shared/lib';
import { CompanyRoute } from 'schedule/shared/model/';

import { CompanyRoutesResponseDto } from '../api/types';

export function mapRoutes(dto: CompanyRoutesResponseDto): CompanyRoute[] {
    const {
        data: { objects: routesList },
    } = dto;

    const routes = routesList.map((route) => toCamelCase(route) as CompanyRoute);

    return routes;
}
