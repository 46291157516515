import { fetcher } from 'checkin/shared/api';
import { toCamelCase } from 'checkin/shared/lib/toCamelCase';

import { MappedSegment } from '../order';

export async function getAvailableSeatsApi(segment: MappedSegment) {
    const params = {
        json: JSON.stringify({
            departure: segment.departureAirportCode,
            arrival: segment.arrivalAirportCode,
            date: segment.departureDate,
            flight: segment.flightNumber,
            company: segment.ak,
        }),
    };

    const response = await fetcher(`api/v1/flights/availableSeats/?${new URLSearchParams(params).toString()}`);

    return toCamelCase(response);
}
