import React, { useState, useEffect, useCallback } from 'react';
import { formatPrice } from 'utils/formatter';
import { extractError } from 'checkin/shared/api';
import { CURRENCIES } from 'consts';

import { useAction } from '../../model';
import {
    getIsSelected,
    changeSelectedServices,
    mapServicesForRemove,
    getTotalPrice,
    getHasChangeInBasket,
} from '../../lib';
import { Card } from '../Card';
import { Segment } from '../Segment';
import { Passenger } from '../Passenger';
import { ServiceItemModal } from '../ServiceItemModal';
import { TYPE_OF_APPLICATION } from '../../consts';

const note = 'Куплено ранее';

export function ServiceItem({
    id: guid,
    image = null,
    name = '',
    dimensions = '',
    description = '',
    segments = [],
    applicability = [],
    checkinServices = [],
    hasBookedServices = false,
    minPrice = 0,
    Action = () => {},
    applyServices = () => {},
    showDetails,
    setShowDetails,
    isDisabledImgInModal = false,
}) {
    const { setError, setIsLoading: setIsGlobalLoading } = useAction();
    const [isLoading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    const total = getTotalPrice(selected, applicability);
    const formattedTotalPrice = formatPrice(total, CURRENCIES.RUR.value, true, true);
    const updateSelected = useCallback(() => setSelected(checkinServices), [checkinServices]);
    const hasChangeInBasket = getHasChangeInBasket(checkinServices, selected);

    useEffect(() => {
        updateSelected();
    }, [updateSelected]);

    const applyService = (typeOfApplication) => {
        setLoading(true);
        applyServices(selected, checkinServices, typeOfApplication)
            .then(() => {
                setShowDetails(false);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                updateSelected();
                setShowDetails(false);
                setError(extractError(e));
            });
    };

    const removeService = () => {
        setIsGlobalLoading(true);
        applyServices(mapServicesForRemove(selected), checkinServices, TYPE_OF_APPLICATION.REMOVE)
            .then(() => setIsGlobalLoading(false))
            .catch((e) => {
                setIsGlobalLoading(false);
                updateSelected();
                setError(extractError(e));
            });
    };

    const onChange = (quantity, segmentIdAsm, passengerIdAsm) =>
        setSelected(changeSelectedServices({ selected, guid, segmentIdAsm, passengerIdAsm, quantity }));

    return (
        <>
            <Card
                imageSrc={image}
                title={name}
                subTitle={dimensions}
                alert={hasBookedServices && note}
                price={`от ${minPrice}`}
                formattedTotalPrice={formattedTotalPrice}
                hasTotalPrice={!!total}
                onAdd={() => setShowDetails(true)}
                onRemove={removeService}
            />
            {showDetails && (
                <ServiceItemModal
                    hasChangeInBasket={hasChangeInBasket}
                    imgSrc={image}
                    isDisabledImage={isDisabledImgInModal}
                    isLoading={isLoading}
                    title={name}
                    subtitle={dimensions}
                    description={description}
                    formattedTotalPrice={formattedTotalPrice}
                    onApplyService={() => applyService(TYPE_OF_APPLICATION.SAVE)}
                    onClose={() => applyService(TYPE_OF_APPLICATION.CLOSE)}
                >
                    {segments.map(({ segmentIdAsm, direction, departureCityName, arrivalCityName, passengers }) => (
                        <Segment
                            key={segmentIdAsm}
                            direction={direction}
                            departure={departureCityName}
                            arrival={arrivalCityName}
                        >
                            {passengers.map(
                                ({ firstName, lastName, passengerIdAsm, price, bookedQuantity, formattedOldPrice }) => (
                                    <Passenger
                                        firstName={firstName}
                                        lastName={lastName}
                                        key={passengerIdAsm}
                                        note={!!bookedQuantity && `${note} \u00D7 ${bookedQuantity}`}
                                        onAdd={() => onChange(1, segmentIdAsm, passengerIdAsm)}
                                        Action={
                                            <Action
                                                selected={selected}
                                                onChange={onChange}
                                                segmentId={segmentIdAsm}
                                                passengerId={passengerIdAsm}
                                            />
                                        }
                                        price={price}
                                        formattedOldPrice={formattedOldPrice}
                                        isSelected={getIsSelected(selected, segmentIdAsm, passengerIdAsm)}
                                    />
                                )
                            )}
                        </Segment>
                    ))}
                </ServiceItemModal>
            )}
        </>
    );
}
