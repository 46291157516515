import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { Backdrop } from './Backdrop';
import { ModalWrapper } from './ModalWrapper';
import { Loader } from './Loader';

export function ServiceModal({ onClose, className, isLoading = false, disableOverlayClick = false, children }) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(true);
    }, []);

    const handleCloseModal = () => {
        setIsOpen(false);
        onClose();
    };

    return createPortal(
        <>
            <Backdrop isOpen={isOpen} onClick={handleCloseModal} disableOverlayClick={disableOverlayClick} />
            <ModalWrapper isOpen={isOpen} className={className}>
                {children}
                <Loader isLoading={isLoading} />
            </ModalWrapper>
        </>,
        document.body
    );
}
