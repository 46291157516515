/* eslint-disable import/no-unresolved */
import { Button } from '@public-projects/utair-ui-kit';
import cn from 'classnames';
import { ReactComponent as ArrowForward } from 'icons/interface/next.svg';
import { ReactComponent as ArrowBack } from 'icons/interface/prev.svg';
import { Moment } from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DayPickerRangeController } from 'react-dates';

import { ISO_DATE_MASK } from 'consts';
import { getEndOfWeek, getStartOfWeek, getToday, useIsMobile } from 'schedule/shared/lib';

import styles from './Calendar.module.scss';

const SHORT_DATE_FORMAT = 'D MMM';

type CalendarProps = {
    fromDate: Moment | null;
    toDate: Moment | null;
    lng?: string;
    availableDates?: DateIso[];
    isShowOnlyAvailableDates?: boolean;
    isError?: boolean;
    onChange: (start: Moment | null, end: Moment | null) => void;
};

export const Calendar = ({
    fromDate = null,
    toDate = null,
    lng = 'ru',
    availableDates = [],
    // isShowOnlyAvailableDates = false,
    // isError = false,
    onChange,
}: CalendarProps) => {
    const [dateLabel, setDateLabel] = useState<string>();
    const [isShowCalendar, setIsShowCalendar] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false);

    const inputRef = useRef(null);
    const dropdownRef = useRef(null);

    const isMobile = useIsMobile();

    useEffect(() => {
        if (fromDate && toDate) {
            const startDateLabel = fromDate.locale(lng).format(SHORT_DATE_FORMAT);
            const endDateLabel = toDate.locale(lng).format(SHORT_DATE_FORMAT);

            setDateLabel(`${startDateLabel} - ${endDateLabel}`);
        } else {
            setDateLabel('');
        }
    }, [fromDate, toDate]); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleShowCalendar = () => {
        setIsShowCalendar(!isShowCalendar);
    };

    const handleFocus = () => {
        toggleShowCalendar();
    };

    const closeCalendar = () => {
        setIsShowCalendar(false);
        setFocused(false);
    };

    const handleDateSelect = (value: { startDate: Moment | null; endDate: Moment | null }) => {
        let start: Moment | null = null;
        let end: Moment | null = null;

        if (value.startDate) {
            start = getStartOfWeek(value.startDate);
            end = getEndOfWeek(value.startDate);
        }

        setFocused(false);
        closeCalendar();
        onChange(start, end);
    };

    const handleFocusChange = () => {
        setFocused(!focused);
    };

    const isOutsideRange = (day: Moment) => {
        if (!day) return false;

        const today = getToday();

        return day.isBefore(today, 'day');
    };

    const handleOutsideClick = useCallback(
        (e: MouseEvent) => {
            if (isShowCalendar && e.target !== dropdownRef.current && e.target !== inputRef.current) {
                setIsShowCalendar(!isShowCalendar);
            }
        },
        [isShowCalendar]
    );

    const isDayHighlighted = (day: Moment) =>
        !(availableDates.length > 0 && availableDates.includes(day.format(ISO_DATE_MASK)));

    const renderDatePresets = () => (
        <Button variant="secondary" onClick={() => handleDateSelect({ startDate: null, endDate: null })} fullWidth>
            Когда угодно
        </Button>
    );

    return (
        <div className={cn(styles.calendar, { [styles.focused]: isShowCalendar })}>
            <input
                ref={inputRef}
                onFocus={handleFocus}
                className={styles.calendarInput}
                type="text"
                placeholder={!dateLabel ? 'Когда угодно' : undefined}
                value={dateLabel}
                readOnly
                tabIndex={0}
            />
            <div
                className={cn(styles.calendarDropdown, {
                    [styles.modal]: isMobile,
                    [styles.isOpen]: isMobile && isShowCalendar,
                })}
                aria-hidden={!isShowCalendar}
            >
                <DayPickerRangeController
                    orientation="horizontal"
                    startDate={fromDate}
                    endDate={toDate}
                    onDatesChange={handleDateSelect}
                    focusedInput="startDate"
                    onFocusChange={handleFocusChange}
                    initialVisibleMonth={() => fromDate || getToday()}
                    onOutsideClick={handleOutsideClick}
                    isOutsideRange={isOutsideRange}
                    isDayHighlighted={isDayHighlighted}
                    numberOfMonths={isMobile ? 1 : 2}
                    firstDayOfWeek={1}
                    renderNavPrevButton={(props) => (
                        <Button variant="secondary" {...props} className={styles.navBtn}>
                            <ArrowBack width={24} height={24} aria-hidden="true" />
                        </Button>
                    )}
                    renderNavNextButton={(props) => (
                        <Button variant="secondary" {...props} className={styles.navBtn}>
                            <ArrowForward width={24} height={24} aria-hidden="true" />
                        </Button>
                    )}
                    hideKeyboardShortcutsPanel
                    renderCalendarInfo={renderDatePresets}
                />
            </div>
        </div>
    );
};
