import React from 'react';

import styles from './Image.module.scss';

export function Image({ className, src, alt }) {
    return (
        <div className={className}>
            <img className={styles.root} src={src} alt={alt} />
        </div>
    );
}
