import React from 'react';
import cn from 'classnames';

import styles from './Tab.module.scss';

export function Tab({ className, label, value, active }) {
    return (
        <a className={cn(styles.root, active && styles.active, className)} href={`#${value}`}>
            {label}
        </a>
    );
}
