import { MomentInput } from 'moment';

import { getDateObject } from './getDateObject';

export function getStartOfWeek(datetime?: MomentInput) {
    const dateObject = getDateObject(datetime);
    const startOfWeek = dateObject.clone().startOf('week');

    return startOfWeek;
}
