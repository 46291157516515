import React, { useEffect } from 'react';
import { Button } from '@public-projects/utair-ui-kit';
import cn from 'classnames';

import styles from './ServiceItemModal.module.scss';
import { Image } from '../Image';
import { ServiceModal } from '../ServiceModal';
import { ReactComponent as CloseIcon } from './close.svg';

export function ServiceItemModal({
    hasChangeInBasket = false,
    imgSrc = null,
    isLoading = false,
    title = '',
    subtitle = '',
    description = '',
    formattedTotalPrice = '0',
    onApplyService = () => {},
    children = null,
    isDisabledImage = false,
}) {
    useEffect(() => {
        const scrollBarWidth = window.innerWidth - (window.document.querySelector('body')?.clientWidth || 0);
        window.document.body.style.paddingRight = `${scrollBarWidth}px`;
        window.document.body.style.overflow = 'hidden';

        return () => {
            window.document.body.style.overflow = 'auto';
            window.document.body.style.paddingRight = '';
        };
    }, []);

    return (
        <ServiceModal
            className={hasChangeInBasket && styles.externalContainer}
            isLoading={isLoading}
            onClose={onApplyService}
            disableOverlayClick
        >
            <>
                <div className={styles.container}>
                    <div className={styles.details}>
                        <div className={styles.main}>
                            <div className={styles.wrapper}>
                                {!isDisabledImage && <Image className={styles.image} src={imgSrc} alt={title} />}
                                <div>
                                    {!!title && <div className={styles.title}>{title}</div>}
                                    {!!subtitle && <div className={styles.subTitle}>{subtitle}</div>}
                                    {!!description && <div className={cn(styles.description)}>{description}</div>}
                                </div>
                            </div>
                            {children}
                        </div>
                    </div>
                    <div className={cn(styles.footer, !hasChangeInBasket && styles.hideFooter)}>
                        <div className={styles.total}>Итого: {formattedTotalPrice}</div>
                        <Button
                            className={styles.button}
                            onClick={onApplyService}
                            data-testid="checkIn-serviceItemModal-saveButton"
                        >
                            Сохранить выбор
                        </Button>
                    </div>
                </div>
                <button className={styles.closeButton} onClick={onApplyService}>
                    <CloseIcon className={styles.closeIcon} />
                </button>
            </>
        </ServiceModal>
    );
}
