import { combineReducers } from '@reduxjs/toolkit';

import { cartModel } from 'entities/upgrade/cart';
import { discountModel } from 'entities/upgrade/discount';
import { notificationModel } from 'entities/upgrade/notifications';
import { orderModel } from 'entities/upgrade/order';
import { seatsModel } from 'entities/upgrade/seats';

export const upgradeReducers = combineReducers({
    [discountModel.name]: discountModel.reducer,
    [orderModel.name]: orderModel.reducer,
    [seatsModel.name]: seatsModel.reducer,
    [cartModel.name]: cartModel.reducer,
    [notificationModel.name]: notificationModel.reducer,
});
