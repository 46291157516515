import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Message, Notifications } from 'shared/ui/Notifications';

import { actions, selectors } from './model';

export function AppNotifications() {
    const dispatch = useAppDispatch();
    const notificationMessages = useAppSelector(selectors.selectMessages);
    const handleClose = useCallback((message: Message) => dispatch(actions.removeMessage(message)), [dispatch]);

    return <Notifications messages={notificationMessages} onClose={handleClose} />;
}
