import React, { useEffect, useRef, useState } from 'react';
import ScrollableTabs from 'checkin/shared/ui/ScrollableTabs';

import { Tab } from './Tab';
import stylesGrid from './GridNext.module.scss';
import styles from './List.module.scss';

export function List({ wrapperRef, items = [], ComponentItem }) {
    const [currentValue, setCurrentValue] = useState(null);

    const listItemsRef = useRef();

    const { current: wrapperElement } = wrapperRef;

    useEffect(() => {
        setCurrentValue(items && items.length ? items[0].value : null);
    }, [items]);

    useEffect(() => {
        const onScrollContent = () => {
            const { scrollTop, offsetHeight } = wrapperElement;
            const { y: wrapperY } = wrapperElement.getBoundingClientRect();
            const { current: listItems } = listItemsRef;

            const { value } = Object.entries(listItems || {}).reduce(
                (result, [key, element]) => {
                    const { clientHeight } = element;
                    const { y, height } = element.getBoundingClientRect();

                    const wrapperStartY = scrollTop + wrapperY;
                    const wrapperEndY = offsetHeight + wrapperStartY;

                    const areaStartY = y + scrollTop;
                    const areaEndY = y + height + scrollTop;

                    /** Координаты границ области элемента (без учета частей, не видимых в данный момент в области-обертке) */
                    const visibleAreaStartY = areaStartY < wrapperStartY ? wrapperStartY : areaStartY;
                    const visibleAreaEndY = areaEndY > wrapperEndY ? wrapperEndY : areaEndY;

                    const visibleAreaRatio = (visibleAreaEndY - visibleAreaStartY) / clientHeight;

                    return visibleAreaRatio > result.maxVisibleAreaRatio
                        ? { value: key, maxVisibleAreaRatio: visibleAreaRatio }
                        : result;
                },
                { value: null, maxVisibleAreaRatio: 0 }
            );

            setCurrentValue(value);
        };

        if (wrapperElement) {
            wrapperElement.addEventListener('scroll', onScrollContent);
        }

        return () => {
            if (wrapperElement) {
                wrapperElement.removeEventListener('scroll', onScrollContent);
            }
        };
    }, [wrapperElement]);

    const attachListItemRef = (key) => (element) => {
        const { current: listItems } = listItemsRef;

        listItemsRef.current = {
            ...(listItems || {}),
            [key]: element,
        };
    };

    return (
        <div className={styles.list}>
            <div className={styles.listHeaderWrapper}>
                <div className={styles.listHeader}>
                    <ScrollableTabs>
                        {items.map(({ label, value }) => (
                            <Tab
                                className={styles.tab}
                                key={value}
                                label={label}
                                value={value}
                                active={value === currentValue}
                            />
                        ))}
                    </ScrollableTabs>
                </div>
            </div>
            {items.map(({ label, value, children }) => (
                <div key={value} ref={attachListItemRef(value)}>
                    <div className={styles.listDivider}>
                        <div id={value} className={styles.anchor} />
                        {label}
                    </div>
                    <div className={stylesGrid.root}>
                        {children.map((props) => (
                            <div key={props.id} className={stylesGrid.item50}>
                                <ComponentItem {...props} />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}
