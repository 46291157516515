import React from 'react';

import styles from './Counter.module.scss';
import { findService } from '../../lib';

export function Counter({ selected, segmentId, passengerId, onChange, min = 0, max = 10 }) {
    const value = findService(selected, segmentId, passengerId)?.quantity || 0;

    return (
        <div className={styles.counter}>
            <button
                className={styles.buttonLeft}
                onClick={() => onChange(value - 1 < min ? value : value - 1, segmentId, passengerId)}
                disabled={value === min}
            >
                -
            </button>
            <div className={styles.value}>{value}</div>
            <button
                className={styles.buttonRight}
                onClick={() => onChange(value + 1 > max ? value : value + 1, segmentId, passengerId)}
                disabled={value === max}
            >
                +
            </button>
        </div>
    );
}
