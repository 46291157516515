import { API_BASE_URL } from 'consts';
import { fetcher } from 'schedule/shared/api';

import { CitiesDto } from './types';

export type GetCitiesApiParams = {
    query?: string;
    code?: string;
    popular?: boolean;
    forCity?: string;
    limit?: number;
    tag?: string;
};

// https://b.utair.ru/api/docs/cities/GET_cities/
export function getCitiesApi({
    query = '',
    code = '',
    popular = false,
    forCity = '',
    limit = 7,
    tag = 'airplane',
}: GetCitiesApiParams) {
    const searchParams = new URLSearchParams({
        limit: limit.toString(),
        tag,
    });

    if (query) searchParams.set('q', query);
    if (code) searchParams.set('code', code);
    if (forCity) searchParams.set('for_city', forCity);
    if (popular) searchParams.set('popular', String(popular));

    const queryString = searchParams.toString();

    return fetcher<CitiesDto>(`${API_BASE_URL}/cities/api/v3/cities?${queryString}`, { method: 'GET' });
}
