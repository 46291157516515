import React from 'react';
import { Button } from '@public-projects/utair-ui-kit';

import styles from './Button.module.scss';

export function CancelButton({ onChange, segmentId, passengerId, quantity = 0 }) {
    return (
        <Button
            className={styles.root}
            size="small"
            variant="secondary"
            onClick={() => onChange(quantity, segmentId, passengerId)}
        >
            Отменить
        </Button>
    );
}
