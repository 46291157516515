import url from 'url';
import { API_URLS, CLIENT_SECRET, CLIENT_ID, PRIVATE_CACHE_FLAG } from 'consts';
import fetch, { parseBody, parseError } from 'utils/fetch';
import { cacheManager } from 'utils/cache-manager';
import captchaManager from 'utils/captcha-manager';
import { utairHttpManager as request } from 'managers/utair';
import { resetUserData } from 'actions/user';
import { resetBonuses } from 'actions/bonuses';
import { getSocialData } from 'components/Account/social/selectors';
import { suggectSocialAuth } from 'components/Account/social/actions';
import { createFormURLEncoded } from 'utils';

import { resetForm, setConfirmationType } from './sign';

/**
 * token response (see OAuth2)
 * @typedef {Object} TokenResponse
 * @property {string} access_token
 * @property {string} refresh_token
 * @property {number} expires_in - per seconds
 * @property {string} token_type - 'Bearer'
 * @property {boolean} [is_application_only]
 * @property {string} scope
 */

/**
 * session data
 * @typedef {Object} Session
 * @property {string} accessToken
 * @property {string} refreshToken
 * @property {Date} accessExpireDate
 * @property {Date} refreshExpireDate
 */

export const SET_USER_AUTH_ATTEMPT_ID = 'SET_USER_AUTH_ATTEMPT_ID';
export const SET_USER_LOGIN = 'SET_USER_LOGIN';
export const SET_IS_AUTH_USER = 'SET_IS_AUTH_USER';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_MODAL_MODE = 'TOGGLE_MODAL_MODE';
export const PORTAL_TOKEN_NAME = 'portal_access_token';

/**
 * lifetime of refresh token per milliseconds
 * 30 days
 */
export const REFRESH_TOKEN_LIFE_TIME = 2592000000;

/**
 * key for client session in local storage
 */
export const CLIENT_SESSION_CACHE_NAME = 'clientSession';

/**
 * key for user session in local storage
 */
export const USER_SESSION_CACHE_NAME = 'userSession';

/**
 * buffer for deferred getting token for client
 * @var {?Promise<string>}
 */
let clientAccessTokenPromise = null;

/**
 * buffer for deferred getting token for user
 * @var {?Promise<string>}
 */
let userAccessTokenPromise = null;

/**
 * map session data from http-response
 * @param {TokenResponse} session
 * @return {Session}
 */
const mapSessionData = (session) => ({
    accessToken: session.access_token,
    refreshToken: session.refresh_token,
    accessExpireDate: new Date(Date.now() + session.expires_in * 1000),
    refreshExpireDate: new Date(Date.now() + session.expires_in * 1000 + REFRESH_TOKEN_LIFE_TIME),
});

/**
 * refresh session by {refreshToken}
 * @param {string} token - refreshToken
 * @return {Promise<TokenResponse>}
 */
const refreshToken = (token) =>
    fetch(API_URLS.AUTH.TOKEN, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: createFormURLEncoded({
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            grant_type: 'refresh_token',
            refresh_token: token,
        }),
    }).then(parseBody);

/**
 * refresh user token and save session to localStorage
 * @param {string} token - refreshToken
 * @return {Promise<Session>}
 */
export const updateUserSession = (token) =>
    refreshToken(token).then((sessionData) => {
        const session = mapSessionData(sessionData);
        cacheManager.setItem(USER_SESSION_CACHE_NAME, session, null, PRIVATE_CACHE_FLAG);

        return session;
    });

export const setSessionData = (sessionData) => {
    const session = mapSessionData(sessionData);
    cacheManager.setItem(USER_SESSION_CACHE_NAME, session, null, PRIVATE_CACHE_FLAG);

    return session;
};

/**
 * fetch token for user
 * @param {string} username - email, phone, "STATUS" card number
 * @param {string} password - temporary session ID
 * @return {Promise<TokenResponse>}
 */
export const fetchUserToken = (username, password) => {
    const base64Creds = btoa(`${CLIENT_ID}:${CLIENT_SECRET}`);

    return fetch(API_URLS.AUTH.TOKEN, {
        method: 'POST',
        headers: {
            Authorization: `Basic ${base64Creds}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: createFormURLEncoded({
            grant_type: 'password',
            username,
            password,
        }),
    }).then(parseBody);
};

function taisAuthAction(params) {
    try {
        const iframe = document.createElement('iframe');

        iframe.src = API_URLS.AUTH.TAIS_AUTH + url.format({ query: params });

        iframe.onload = function onLoadIFrame() {
            this.parentNode.removeChild(this);
        };

        document.body.appendChild(iframe);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error tais auth action');
    }
}

/**
 * fetch client token and save session
 * @param {string} username - email, phone, "STATUS" card number
 * @param {string} password - temporary session ID
 * @return {Promise<Session>}
 */
export const authorizeUser = (username, password) =>
    fetchUserToken(username, password).then((sessionData) => {
        taisAuthAction(sessionData);

        return setSessionData(sessionData);
    });

/**
 * save temporary {attemptId} for user authorization
 * between login and loginConfirm actions
 * @param {?string} attemptId
 */
export const setUserAuthAttemptId = (attemptId) => ({
    type: SET_USER_AUTH_ATTEMPT_ID,
    payload: attemptId,
});

/**
 * save {userLogin} for user authorization
 * between login and loginConfirm actions
 * @param {?string} userLogin
 */
export const setUserLogin = (userLogin) => ({
    type: SET_USER_LOGIN,
    payload: userLogin,
});

export const toggleModal = (isOpen) => ({
    type: TOGGLE_MODAL,
    payload: isOpen,
});

export const toggleModalMode = (mode) => ({
    type: TOGGLE_MODAL_MODE,
    payload: mode,
});

/**
 * get user accessToken
 * - from localStorage
 * - or update session if accessToken is expired
 * notice: return buffered promise {userAccessTokenPromise} if request is fetching
 * @return {Promise<string>} accessToken
 */
export const getUserAccessToken = () => {
    if (userAccessTokenPromise) {
        return userAccessTokenPromise;
    }

    const userSession = cacheManager.getItem(USER_SESSION_CACHE_NAME);

    if (userSession) {
        const accessExpireDate = new Date(userSession.accessExpireDate);
        const refreshExpireDate = new Date(userSession.refreshExpireDate);
        const now = new Date();

        if (accessExpireDate > now) {
            return Promise.resolve(userSession.accessToken);
        }

        if (refreshExpireDate > now) {
            userAccessTokenPromise = updateUserSession(userSession.refreshToken)
                .then((session) => {
                    userAccessTokenPromise = null;

                    return session.accessToken;
                })
                .catch(parseError);

            return userAccessTokenPromise;
        }
    }

    return Promise.reject(new Error('Unauthorized'));
};

/**
 * fetch token for client
 * @return {Promise<TokenResponse>}
 */
const fetchClientToken = () =>
    fetch(API_URLS.AUTH.TOKEN, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: createFormURLEncoded({
            client_id: CLIENT_ID,
            grant_type: 'client_credentials',
        }),
    }).then(parseBody);

/**
 * remove session by {accessToken}
 * @param {string} accessToken
 * @return {Promise}
 */
const revokeToken = () =>
    getUserAccessToken().then((accessToken) =>
        fetch(API_URLS.AUTH.REVOKE_TOKEN, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: createFormURLEncoded({
                grant_type: 'revoke_token',
                token: accessToken,
                client_id: CLIENT_ID,
            }),
        })
    );

/**
 * user is authorized if user session cache exists
 * @param {boolean} isAuthUser
 */
export const setIsAuthUser = (isAuthUser) => ({
    type: SET_IS_AUTH_USER,
    payload: isAuthUser,
});

/**
 * fetch client token and save session
 * @return {Promise<Session>}
 */
const authorizeClient = () =>
    fetchClientToken().then((sessionData) => {
        const session = {
            accessToken: sessionData.access_token,
            accessExpireDate: new Date(Date.now() + sessionData.expires_in * 1000),
        };
        cacheManager.setItem(CLIENT_SESSION_CACHE_NAME, session);

        return session;
    });

/**
 * - reduce isAuthUser
 * - listen changes of cached session
 */
export const initAuth = () => (dispatch) => {
    const searchParams = new URLSearchParams(document.location.search);

    if (searchParams.get(PORTAL_TOKEN_NAME)) {
        dispatch(
            suggectSocialAuth('portal', { access_token: searchParams.get(PORTAL_TOKEN_NAME) }, () => {
                const [href] = document.location.href.split('?');
                document.location.href = href;
            })
        );
    } else {
        const userSession = cacheManager.getItem(USER_SESSION_CACHE_NAME);

        if (userSession) {
            const refreshExpireDate = new Date(userSession.refreshExpireDate);

            // Временный костыль для фикса ошибки авторизации при переходе с мобвеба на десктоп
            // мобвеб при обновлении токена получает клиентский рефреш токен
            // вида guest::2e0d81cd-8bb0-41d4-8eae-160f21d2dd2a и записывает его в userSession
            // когда мы переходим на десктопную версию сайта, то пытаемся обновить токен юзера
            // через этот клиентский токен, записанный в userSession,
            // из-за чего постоянно падаем

            const isInvalidRefreshToken = userSession.refreshToken
                ? userSession.refreshToken.includes('guest::')
                : true;

            if (isInvalidRefreshToken) {
                cacheManager.removeItem(USER_SESSION_CACHE_NAME);
            }

            if (!isInvalidRefreshToken && refreshExpireDate > new Date()) {
                dispatch(setIsAuthUser(true));
            }
        }

        cacheManager.subscribe(USER_SESSION_CACHE_NAME, (event) => {
            const isSessionExist = event.newValue !== null;

            dispatch(setIsAuthUser(isSessionExist));

            if (!isSessionExist) {
                dispatch(resetUserData());
                dispatch(resetBonuses());
            }
        });
    }
};

/**
 * get client accessToken
 * - from localStorage
 * - update session if accessToken is expired
 * notice: return buffered promise {clientAccessTokenPromise} if request is fetching
 * @return {Promise<string>} accessToken
 */
export const getClientAccessToken = () => {
    if (clientAccessTokenPromise) {
        return clientAccessTokenPromise;
    }

    const clientSession = cacheManager.getItem(CLIENT_SESSION_CACHE_NAME);
    let sessionPromise;

    if (clientSession) {
        const accessExpireDate = new Date(clientSession.accessExpireDate);
        const now = new Date();

        if (accessExpireDate > now) {
            return Promise.resolve(clientSession.accessToken);
        }
    }

    if (sessionPromise === undefined) {
        sessionPromise = authorizeClient();
    }

    clientAccessTokenPromise = sessionPromise
        .then((session) => {
            clientAccessTokenPromise = null;

            return session.accessToken;
        })
        .catch(parseError);

    return clientAccessTokenPromise;
};

/**
 * user authorization: first step
 * authorize request for user
 * @param {string} loginKey - email, phone, "STATUS" card number
 */
export const login = (loginKey) => (dispatch) => {
    dispatch(setUserLogin(loginKey));

    return captchaManager.getCaptchaToken().then((captchaToken) =>
        fetch(API_URLS.AUTH.LOGIN, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'g-recaptcha-response': captchaToken,
            },
            body: JSON.stringify({
                login: loginKey,
                confirmation_type: 'standard',
            }),
        })
            .then(parseBody)
            .then((data) => {
                dispatch(setUserAuthAttemptId(data.attempt_id));
                dispatch(setConfirmationType(data.confirmation_type));

                return data;
            })
            .catch(parseError)
    );
};

/**
 * user authorization: second step
 * confirm code received by user
 * uses cookie-based auth
 * @param {string} code - confirm code from sms- or email-message
 */
export const loginConfirm = (code) => (dispatch, getState) => {
    const { userAuthAttemptId, userLogin } = getState().auth;
    const socialData = getSocialData(getState());

    const requestParams = {
        attempt_id: userAuthAttemptId,
        code,
        ...socialData,
    };

    return request
        .post(API_URLS.AUTH.LOGIN_CONFIRM, requestParams, false)
        .then((data) => authorizeUser(userLogin, data.session))
        .then(() => {
            dispatch(setUserLogin(null));
            dispatch(setUserAuthAttemptId(null));
            dispatch(setIsAuthUser(true));
        });
};

/**
 * revoke user token and clear session
 */
export const logout =
    (show = true, messageOnReload) =>
    (dispatch) => {
        dispatch(setIsAuthUser(false));
        dispatch(resetUserData());
        dispatch(resetBonuses());
        dispatch(resetForm());

        dispatch(toggleModal(show));

        taisAuthAction({ logout: 'yes' });

        return revokeToken()
            .then(() => {
                cacheManager.removeByFlag(PRIVATE_CACHE_FLAG);
                localStorage.clear();

                if (messageOnReload) {
                    cacheManager.setItem('sysMessageOnReload', messageOnReload);
                }

                window.location = '/';
            })
            .catch(() => {
                cacheManager.removeByFlag(PRIVATE_CACHE_FLAG);
                localStorage.clear();
                window.location = '/';
            });
    };

export const logoutWithoutRedirectToMainPage = () => (dispatch) => {
    dispatch(setIsAuthUser(false));
    dispatch(resetUserData());
    dispatch(resetBonuses());
    dispatch(resetForm());

    taisAuthAction({ logout: 'yes' });

    return revokeToken().finally(() => {
        cacheManager.removeByFlag(PRIVATE_CACHE_FLAG);
        localStorage.clear();
    });
};
