import { Moment } from 'moment';

import { getDateObject } from './getDateObject';

export function getIsoDate(datetime: Moment | DateTimeIso | null) {
    if (!datetime) return null;

    const dateObject = getDateObject(datetime);

    return dateObject.format('YYYY-MM-DD');
}
