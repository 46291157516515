import { toCamelCase } from 'schedule/shared/lib';
import { type CompanyRoute, type CompanyRoutesByCountry } from 'schedule/shared/model';

import { CompanyRoutesByCountryResponseDto } from '../api/types';

export function mapRoutesByCountry(dto: CompanyRoutesByCountryResponseDto): CompanyRoutesByCountry {
    const {
        data: { domestic: domesticRoutes, international: internationalRoutes },
    } = dto;

    const domestic = domesticRoutes.map((route) => toCamelCase(route) as CompanyRoute);
    const international = internationalRoutes.map((route) => toCamelCase(route) as CompanyRoute);

    return { domestic, international };
}
